import React from 'react'
import { Link } from 'react-scroll';
import logo from '../images/logo-light.webp'
import { Container, Row, Col } from 'react-bootstrap'
import img1 from '../images/aerial-view-countryside-railway.webp'
import img2 from '../images/aerial-view-plains-fields_mob.webp'
import { HiMiniBars3 } from "react-icons/hi2";
import { useMediaQuery } from 'react-responsive';
import { setToggleTrue } from '../redux/slice/changeState'
import { useDispatch, useSelector} from 'react-redux';
import ContactModal from './ContactModal';
import { saveAs } from "file-saver";
import pdf from "../The RIpple_Presentation.pdf"
const Banner = () => {
    const dispatch = useDispatch()
    const isMobile = useMediaQuery({ query: '(max-width: 500px)' });
    const isSmallLaptop = useMediaQuery({ query: '(max-width: 991px)' });
    const toggle = useSelector((state) => state.toggle);
    const handleDownload = () => {
        saveAs(pdf, "IRA_Ripples_Brochure.pdf");
    };
    return (
        <>
        <div className='top-section' style={{backgroundImage : `url(${isMobile ? img2 :img1 })`}}>
            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#"><img src={logo} alt="logo"></img></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                        <HiMiniBars3 fill="#fff" size={30}/>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                        <ul className="navbar-nav mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link to="/" smooth={true} duration={1000}>Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="facility" smooth={true} duration={1000}>Amenities</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="pricing" smooth={true} duration={1000}>Pricing</Link>
                            </li>
                            <li className="nav-item">
                                <a onClick={() => dispatch(setToggleTrue())}>Contact Us</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className='banner'>
                <Container>
                <Row>
                    <Col lg={8}>
                        <div>
                            <h5>Premium Plots in Cheemaldhari</h5>
                            
                            
                            <h1 className='text-white text-uppercase mb-4'>Craft Your Vision at IRA Ripples</h1>
                            {isMobile ?
                                <>
                                <h6>160 acres of DTCP & RERA-approved plots</h6>
                                <h6>near Shankarpally, designed for your dream</h6>
                                <h6>projects. Choose from plot sizes of 600 to</h6>
                                <h6 className='mb-5'>1200 Sq.yds in a location primed for growth."</h6>
                                </>
                            :

                            <h6 className='text-white mb-5 text-capitalize'>160 acres of DTCP & RERA-approved plots near Shankarpally, designed for your dream projects. Choose from plot sizes of 600 to 1200 Sq.yds in a location primed for growth."</h6>
                            }
                            
                        </div>
                    </Col>
                    <Col lg={4}></Col>
                </Row>
                {isSmallLaptop ? 
                <>
                    <div>
                        <div>
                            <button className='common-btn mb-4'>Book a Site Visit</button>
                            <button className='odd-btn' onClick={handleDownload}>Download Brochure</button>
                        </div>
                    </div>
                    <div><p className='text-white rera-number'>TS RERA: P02100009024</p></div>
                    </>
                :
                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            <button className='common-btn' onClick={() => dispatch(setToggleTrue())}>Book a Site Visit</button>
                            <button className='odd-btn' onClick={handleDownload}>Download Brochure</button>
                        </div>
                        
                    </div>
                }
                
                </Container>
            </div>
        </div>
        {
            toggle.hastoggle && <ContactModal />

        }
        </>
    )
}

export default Banner
