import React from 'react'
import img1 from '../images/Vector-light.webp'
import img2 from '../images/cultivated-field-from.webp'
import { Container, Row, Col } from 'react-bootstrap'
import icon1 from '../images/rupee.webp'
import icon2 from '../images/map.webp'
import icon3 from '../images/home.webp'
import icon4 from '../images/Layer_1.webp'
import { useMediaQuery } from 'react-responsive'
import img3 from '../images/vector-light-mob.webp'
import img4 from '../images/cultivated-field-from_mob.webp'

const Whyira = () => {
    const isSmallMobile = useMediaQuery({ query: '(max-width: 500px)' });
    const isSmallLaptop = useMediaQuery({ query: '(max-width: 991px)' });
  return (
    <div className="position-relative">
        <div className='whyira_section position-relative'>
        <Container className='position-relative z-1'>
            <Row className='mb-5'>
                <Col lg={6} md={12} >
                {
                    isSmallMobile ?
                    <>
                    <h2>Why IRA Ripples</h2>
                    <h2>is the Place to Be</h2>
                    </>
                    :
                    <h2>Why IRA Ripples is the Place to Be</h2>
                }
                    
                </Col>
                <Col lg={6}></Col>
            </Row>
            <Row>
                {
                    !isSmallLaptop &&
                    
                    <Col lg={4}>
                        <img src={img2} alt="" className='img-fluid'></img>   
                    </Col>

                }
                
                <Col lg={8} className='d-flex align-items-center'>
                    <div style={{paddingLeft: isSmallLaptop ? "" :"20px"}}>
                        <div className='row' style={{marginBottom: isSmallLaptop ? "" : "17%"}}>
                            <div className='col-lg-6 col-sm-6 location-info'>
                                <button type="button" className='mb-3'><img src={icon2} alt="" className='img-fluid'></img></button>
                                <h6 className='mb-2'>Minutes to Everywhere:</h6>
                                <p>From work to relaxation, IRA Ripples keeps you close to life’s essentials.</p>
                            </div>
                            <div className='col-lg-6 col-sm-6 location-info'>
                                <button type="button" className='mb-3'><img src={icon3} alt="" className='img-fluid'></img></button>
                                <h6 className='mb-2'>Future-Ready Infrastructure:</h6>
                                <p>Plots of 600-1200 Sq.yds designed for dreamers.</p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6 col-sm-6 location-info'>
                                <button type="button" className='mb-3'><img src={icon1} alt="" className='img-fluid'></img></button>
                                <h6 className='mb-2'>Affordable Luxury:</h6>
                                <p>At ₹18,000/Sq.yd, build more for less.</p>
                            </div>
                            <div className='col-lg-6 col-sm-6 location-info'>
                                <button type="button" className='mb-3'><img src={icon4} alt="" className='img-fluid'></img></button>
                                <h6 className='mb-2'>Secure Investment:</h6>
                                <p>Backed by DTCP & RERA approvals (RERA No.: P02100009024).</p>
                            </div>
                        </div>
                    </div>
                </Col>
                {
                    isSmallLaptop &&
                    
                    <Col lg={4}>
                        <img src={img4} alt="" className='img-fluid'></img>   
                    </Col>
                }
            </Row>
        </Container>
        { !isSmallLaptop && 
        <img src={img1} alt="" className='img-fluid position-absolute bottom-0'></img>
        }
        </div>
        {isSmallLaptop && 
            <img src={img3} alt="" className='img-fluid position-absolute bottom-0 w-100'></img>
        }
    </div>
  )
}

export default Whyira
