import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import img1 from '../images/mask1.webp'
import img2 from '../images/mask2.webp'
import img3 from '../images/mask3.webp'
import vc from '../images/vector.webp'
import vc_mob from '../images/vector_mob.webp'
import { useMediaQuery } from 'react-responsive'

const Facility = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
    return (
        <div className='position-relative' id="facility">
            <div className='facility-section position-relative'>
                <Container className='position-relative z-2'>
                    <div className={`${isMobile ? "text-start" : "text-center"} text-white mb-5`}>
                        {isMobile ?
                            <>
                                <h2>Education?</h2>
                                <h2>Covered.</h2>
                                <h2>Entertainment?</h2>
                                <h2>Just Around the</h2>
                                <h2>Corner.</h2>
                            </>
                            :
                            <>
                                <h2 >Education? Covered. Entertainment? </h2>
                                <h2>Just Around the Corner.</h2>
                            </>
                        }

                    </div>
                    <Row>
                        <div className="col-lg-4 col-md-6 col-6">
                            <div className="card">
                                <div className={`img-div ${isMobile ? "mb-3" : "mb-4"}`}>
                                    <img src={img1} alt="" />
                                </div>
                                <h5 className={`${isMobile ? "mb-2" : "mb-4"}`}>
                                    Schools That Shape Futures:
                                </h5>
                                <ul>
                                    <li><h6>Glendale Academy:</h6><p>25 minutes</p></li>
                                    <li><h6>Indus International School:</h6><p>20 minutes</p></li>
                                    <li><h6>Gaudium International School:</h6><p>30 minutes</p></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-6">
                            <div className="card">
                                <div className="img-div mb-4">
                                    <img src={img2} alt="" />
                                </div>
                                <h5 className={`${isMobile ? "mb-2" : "mb-4"}`}>Health comes first:</h5>
                                <ul>
                                    <li><h6>Continental Hospitals:</h6><p>30 minutes</p></li>
                                    <li><h6>Care Hospitals, Gachibowli:</h6><p>35 minutes</p></li>
                                </ul>
                            </div>
                        </div>
                        <div className={`${isMobile ? "mt-3 offset-md-3 offset-3" : ""} col-lg-4 col-md-6 col-6`}>
                            <div className="card">
                                <div className="img-div mb-4">
                                    <img src={img3} alt="" />
                                </div>
                                <h5 className={`${isMobile ? "mb-2" : "mb-4"}`}>Retail & Fun:</h5>
                                <ul>
                                    <li><h6>AMR Mall & Multiplex:</h6><p>10 minutes away for weekend movie nights.</p></li>
                                    <li><h6>Kondapur Central Mall:</h6><p>40 minutes, because shopping is serious business.</p></li>
                                </ul>
                            </div>
                        </div>
                    </Row>
                </Container>
                {
                    !isMobile &&
                    <img src={vc} alt="vector" className='img-fluid position-absolute bottom-0 z-1'></img>
                }
            </div>
            {
                isMobile &&
                <img src={vc_mob} alt="vector" className='img-fluid position-absolute w-100 bottom-0 z-1'></img>
            }
        </div>
    )
}

export default Facility

