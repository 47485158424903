import React from 'react'
import * as common from '../Component'

const Home = () => {
  return (
    <>
      <common.Banner />
      <common.Whyira />
      <common.About />
      <common.Facility />
      <common.Pricing />
      <common.Contact />
      <common.Footer />
    </>
  )
}

export default Home
