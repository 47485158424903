import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import img1 from '../images/Frame.webp'
import img2 from '../images/Graph.webp'
import img3 from '../images/clock.webp'
import img4 from '../images/monument.webp'
import img5 from '../images/bg1.webp'
import img6 from '../images/bg1_mob.webp'
import { useMediaQuery } from 'react-responsive'
import { setToggleTrue } from '../redux/slice/changeState'
import { useDispatch, useSelector} from 'react-redux';


const Pricing = () => {
    const dispatch = useDispatch()
    
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
    return (
        <div className='position-relative z-1' id="pricing">

            <div className='pricing-section position-relative'>
                <Container>
                    <div className={`${isMobile ? "text-start" : "text-center"}`}>
                        {isMobile ?
                            <>
                                <h2>Your Land.</h2>
                                <h2>Your Investment.</h2>
                                <h2>Your Future.</h2>
                            </>
                            :
                            <>
                                <h2>Your Land.</h2>
                                <h2>Your Investment. Your Future.</h2>
                            </>
                        }

                    </div>
                    {isMobile ?
                        <>
                            <Row className='mt-5'>
                                <div className='col-md-8 col-8'>
                                    <div className='card'>
                                        <Row className='limited-row'>
                                            <Col md={9} xs={9}>
                                                <div className='limited-text'><span>Limited plots available!</span></div>
                                            </Col>
                                            <Col md={3} xs={3}>
                                                <img src={img3} alt="monument" className='img-fluid'></img>
                                            </Col>
                                        </Row>
                                        <div className='mx-2'>
                                            <Row className='my-3'>
                                                <Col md={7} xs={7}>
                                                    <h5>Plots</h5>
                                                    <span>Starting at</span>
                                                </Col>
                                                <Col md={5} xs={5} className='text-center'>
                                                    <img src={img1} alt="monument"></img>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col><h6>₹18,000/Sq.yd</h6></Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <p>The smartest deal in Hyderabad’s next growth hub.</p>
                                                </Col>
                                            </Row>
                                        </div>
                                        <button className='common-btn mt-3'>Book Today</button>
                                    </div>
                                </div>
                                <div className='col-md-4 col-4'></div>
                            </Row>
                            <Row className='mt-4'>
                                <div className='col-md-3 col-2'></div>
                                <div className='col-md-6 col-8'>
                                    <div className='card name-card'>
                                        <img src={img4} alt="monument"></img>
                                        <h5 className='mt-3'>As Hyderabad expands, <span>Cheemaldhari</span></h5>
                                        <p>is emerging as a preferred location for investors and homeowners alike. Be part of the growth story now.</p>
                                    </div>
                                </div>
                                <div className='col-md-3 col-2'></div>
                            </Row>
                            <Row className='mt-4'>
                                <div className='col-md-4 col-4'></div>
                                <div className='col-md-8 col-8'>
                                    <div className='card name-card'>
                                        <img src={img2} alt="monument"></img>
                                        <h5>The Growth</h5>
                                        <h6>Story:</h6>
                                        <p>With Cheemaldhari’s rapid development, property values are steadily on the rise. Secure your investment today and watch it grow!</p>
                                    </div>
                                </div>
                            </Row>
                        </>
                        :
                        <Row className='mt-5 content-row'>
                            <Col lg={4}>
                                <div className='card name-card'>
                                    <img src={img4} alt="monument"></img>
                                    <h5>As Hyderabad expands, <span>Cheemaldhari</span></h5>
                                    <h6> </h6>
                                    <p>is emerging as a preferred location for investors and homeowners alike. Be part of the growth story now.</p>
                                </div>
                            </Col>
                            <Col lg={4} style={{ marginTop: "60px" }}>
                                <div className='card'>
                                    <Row className='limited-row'>
                                        <Col lg={10}>
                                            <div className='limited-text'><span>Limited plots available!</span></div>
                                        </Col>
                                        <Col lg={2}>
                                            <img src={img3} alt="monument"></img>
                                        </Col>
                                    </Row>
                                    <div className='mx-4'>
                                        <Row className='my-3'>
                                            <Col lg={7}>
                                                <h5>Plots</h5>
                                                <span>Starting at</span>
                                            </Col>
                                            <Col lg={5} className='text-center'>
                                                <img src={img1} alt="monument"></img>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col><h6>₹18,000/Sq.yd</h6></Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <p>The smartest deal in Hyderabad’s next growth hub.</p>
                                            </Col>
                                        </Row>
                                    </div>
                                    <button className='common-btn mt-3' onClick={() => dispatch(setToggleTrue())}>Book Today</button>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className='card name-card'>
                                    <img src={img2} alt="monument"></img>
                                    <h5>The Growth</h5>
                                    <h6>Story:</h6>
                                    <p>With Cheemaldhari’s rapid development, property values are steadily on the rise. Secure your investment today and watch it grow!</p>
                                </div>
                            </Col>
                        </Row>
                    }
                </Container>
                {!isMobile &&
                    <img src={img5} alt="back" className='img-fluid position-absolute bottom-0 z-1' />
                }
            </div>
            {isMobile &&
                <img src={img6} alt="back" className='img-fluid position-absolute z-2 pricing-bg' />
            }
        </div>
    )
}

export default Pricing
