import React from 'react'
import img1 from '../images/mask-group.webp'
import { Container, Row, Col } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'

const About = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
  return (
    <div className='about-section'>
      <Container>
        <Row>
            <Col lg={6}  className='d-flex align-items-center'>
                <div>
                    <h2>Welcome to Your Playground of Possibilities</h2>
                    <h6>IRA Ripples isn’t just land—it’s a launchpad for your biggest ideas. Whether you’re dreaming of a peaceful home, a thriving business, or a smart investment, our 160 acres in Cheemaldhari is the perfect starting point. Seamlessly connected to the Regional Ring Road and Hyderabad’s IT hubs, this is where dreams meet opportunity.</h6>
                    <button className='common-btn'>View RERA Certificate</button>
                </div>
            </Col>
            <Col lg={6} style={{marginTop: isMobile ? "20px" : ""}}>
            <img src={img1} className='img-fluid' alt=""></img>
            </Col>
        </Row>
      </Container>
    </div>
  )
}

export default About
