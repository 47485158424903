import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import desktop from '../images/aerial-view-plains-fields.webp'
import mob from '../images/aerial-view-plains-fields_mob.webp'
import { useMediaQuery } from 'react-responsive';
import ContactForm from './ContactForm';
import { saveAs } from "file-saver";
import pdf from "../The RIpple_Presentation.pdf"

const Contact = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
      const handleDownload = () => {
            saveAs(pdf, "IRA_Ripples_Brochure.pdf");
        };
  return (
    <div className='contact-section' style={{backgroundImage: `url(${isMobile ? mob : desktop })`}}>
      <Container>
        <Row>
            <Col lg={5} md={12} xs={12} className='d-flex align-items-center'>
              <div className='text-white'>
                <h2 className='mb-4'>The Land You’ve Been Waiting For</h2>
                <h6 className='mb-3'>Don’t just dream of the future—start building it today at IRA Ripples. With limited plots available, secure your place in Cheemaldhari’s most exciting development now!</h6>
                <button className='odd-btn' onClick={handleDownload}>Download Brochure</button>
              </div>
            </Col>
            <Col lg={7} md={12} xs={12} className={`${isMobile ? "mt-4" : ""}`}>
              <div className='contact-form-card card'>
                <ContactForm />
              </div>
            </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Contact
